import barba from '@barba/core';
import Swiper from 'swiper/bundle';
import { hideAll } from 'tippy.js';
import { OBSERVER } from '../plugins';
import { isVisible } from './helper';

// Swiper des filtres
export function swiperFilters() {
  if (document.querySelector('[data-swiper="filters"]')) {
    window.swiperFilter = new Swiper('[data-swiper="filters"]', {
      slidesPerView: 'auto',
      speed: 300,
      spaceBetween: 8,
      navigation: {
        prevEl: '[data-swiper="filters-swiper-prev"]',
        nextEl: '[data-swiper="filters-swiper-next"]',
      },
    });
  }
}

// Swiper de la bannière
export function swiperHomeBanner() {
  const slides = document.querySelectorAll('[data-swiper="home-banner-slide"]');
  const slidesLength = slides.length;

  if (document.querySelector('[data-swiper="home-banner"]')) {
    window.swiperHomeBanner = new Swiper('[data-swiper="home-banner"]', {
      loop: slidesLength > 1,
      slidesPerView: 1,
      autoplay: {
        delay: 7000,
      },
      autoHeight: true,
      speed: 1000,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      pagination: {
        el: '[data-swiper="home-banner-pagination"]',
        type: 'bullets',
        clickable: true,
      },
      breakpoints: {
        700: {
          autoHeight: false,
        },
      },
      runCallbacksOnInit: true,
      on: {
        slideChange() {
          hideAll();
        },
      },
    });

    // Mettre en marche/arrêt l'autoplay selon si le swiper est dans le viewport
    const onScroll = () => {
      const homeBanner = document.querySelector('[data-swiper="home-banner"]');

      if (isVisible(homeBanner) && !window.swiperHomeBanner.autoplay.running) {
        window.swiperHomeBanner.autoplay.start();
      } else if (!isVisible(homeBanner) && window.swiperHomeBanner.autoplay.running) {
        window.swiperHomeBanner.autoplay.stop();
      }
    };

    OBSERVER.add({
      name: 'swiperHomeBanner',
      events: 'scroll load',
      targets: 'window',
      function: onScroll,
    });
    OBSERVER.on('swiperHomeBanner');
  }
}

// Swiper de l'alerte
export function swiperAlert() {
  if (document.querySelector('[data-swiper="alerts"]')) {
    const onClose = new CustomEvent('onCloseAlerts');
    const alertElement = document.querySelector('.c-alert');
    const asideElement = document.querySelector('.c-aside');

    if (alertElement) {
      alertElement.classList.add('--visible');
    }

    if (asideElement) {
      alertElement.classList.add('--with-aside');
    }

    const closeAlerts = () => {
      dispatchEvent(onClose);
      alertElement.classList.add('--closed');
    };

    OBSERVER.add({
      name: 'alerts',
      events: 'click',
      function: closeAlerts,
      targets: '[data-swiper="swiper-alerts-close"]',
    });
    OBSERVER.on('alerts');

    window.swiperAlert = new Swiper('[data-swiper="alerts"]', {
      loop: true,
      slidesPerView: 1,
      autoplay: {
        delay: 7000,
      },
      speed: 700,
      pagination: {
        el: '[data-swiper="swiper-alerts-pagination"]',
        type: 'fraction',
      },
      navigation: {
        prevEl: '[data-swiper="swiper-alerts-prev"]',
        nextEl: '[data-swiper="swiper-alerts-next"]',
      },
      runCallbacksOnInit: true,
      on: {
        init(swm) {
          const fraction = document.querySelector('[data-swiper="swiper-alerts-pagination-fraction"]');
          fraction.innerHTML = `${swm.realIndex + 1}/${swm.slides.length - (swm.loopedSlides * 2)}`;
        },
        slideChange(swm) {
          const fraction = document.querySelector('[data-swiper="swiper-alerts-pagination-fraction"]');
          fraction.innerHTML = `${swm.realIndex + 1}/${swm.slides.length - (swm.loopedSlides * 2)}`;
        },
      },
    });

    barba.hooks.afterLeave(() => {
      window.swiperAlert.destroy();
    });
  }
}
