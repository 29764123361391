import createUniqueID from '@blankonumerique/blanko-scripts/create-unique-ID';

export default class CustomizedButton {
  // CONSTRUCTOR
  constructor(button, options) {
    this.button = button;
    this.id = `customizedButton-${createUniqueID()}`;
    this.content = button.innerHTML;
    this.options = options;
    this.setDefaultOptions();
  }

  // PRIVATE
  // Attribution des valeurs par défaut des options de la classe
  setDefaultOptions() {
    this.options.svgName = this.options.svgName || 'none';
    this.options.svgPosition = this.options.svgPosition || 'right';
    this.options.targetBlank = this.options.targetBlank || false;
  }

  // Ajoute un span à l'intérieur du bouton
  addSpan() {
    const NODE = document.createElement('span');
    NODE.innerHTML = this.content;
    this.button.appendChild(NODE);
  }

  // Ajoute un svg à l'intérieur du bouton, mais à l'extérieur du span ajouté
  addSvg() {
    if (this.options.svgName === 'none') {
      return;
    }

    const SVG_NODE = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const USE_NODE = document.createElementNS('http://www.w3.org/2000/svg', 'use');
    USE_NODE.setAttributeNS(
      'http://www.w3.org/1999/xlink',
      'xlink:href',
      `/themes/${window.config.theme_path || 'core'}/assets/medias/images/icons/symbols.svg#ico-${this.options.svgName}`,
    );
    SVG_NODE.appendChild(USE_NODE);
    if (this.options.svgPosition === 'right') {
      this.button.appendChild(SVG_NODE);
    } else {
      this.button.prepend(SVG_NODE);
    }
  }

  // Ajoute un target blank au bouton
  addTargetBlank() {
    const BUTTON_IS_NOT_BLANK = this.button.target !== '_blank';
    const BUTTON_IS_NOT_JS = this.button.href !== 'javascript:;';
    const BUTTON_IS_NOT_SAME_PATH = window.location.hostname !== this.button.hostname;
    const IS_EXTENSION = this.button.pathname.split('/').pop().indexOf('.') > -1;
    const BUTTON_IS_NOT_SAME_PROTOCOL = window.location.protocol !== this.button.protocol;

    if ((BUTTON_IS_NOT_BLANK && BUTTON_IS_NOT_JS) && (BUTTON_IS_NOT_SAME_PATH || IS_EXTENSION || BUTTON_IS_NOT_SAME_PROTOCOL)) {
      this.button.setAttribute('target', '_blank');
    }
  }

  // PUBLIC
  // Initialise la classe
  init() {
    this.button.id = this.id;
    this.button.innerHTML = '';

    this.addTargetBlank();
    this.addSpan();
    this.addSvg();

    this.button.classList.add(this.options.initializationClassName);
  }
}
