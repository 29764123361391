import ShareButtons from 'share-buttons/dist/share-buttons';
import { OBSERVER } from '../plugins';
import Overlay from '../vendor/overlay';

export function overlayShare() {
  window.overlayShare = new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '[data-js="open-overlay-share"]',
        close: '[data-js="close-overlay-share"]',
      },
    },
    options: {
      speed: 800,
    },
  });

  window.overlayShare.init();

  // Gestion de l'ouverture de l'overlay ----------------------
  function openShareOverlay(e) {
    if (e.currentTarget.dataset.rubric) {
      window.history.pushState(null, null, `#${e.currentTarget.dataset.rubric}`);
    }
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    ShareButtons.update();
  }
  OBSERVER.add({
    name: 'openShareOverlay',
    events: 'click',
    targets: '.js-share',
    function: openShareOverlay,
  });
  OBSERVER.on('openShareOverlay');

  // Gestion de la fermeture de l'overlay ---------------------
  function closeShareOverlay() {
    window.history.replaceState(null, null, ' ');
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    ShareButtons.update();
  }

  OBSERVER.add({
    name: 'closeShareOverlay',
    events: 'click',
    targets: '.overlay-share__background, .overlay-share__close',
    function: closeShareOverlay,
  });
  OBSERVER.on('closeShareOverlay');

  // Message de confirmation de copie de l'URL ----------------
  const confirmClipboardCopy = () => {
    setTimeout(() => {
      // eslint-disable-next-line no-alert
      alert('L’adresse URL a bien été copiée dans votre presse-papier.');
    }, 300);
  };

  OBSERVER.add({
    name: 'confirmClipboardCopy',
    events: 'click',
    targets: '.js-share-copy',
    function: confirmClipboardCopy,
  });
  OBSERVER.on('confirmClipboardCopy');
}

export function overlayPopup() {
  if (document.querySelector('[data-overlay="popup"]')) {
    window.overlayPopup = new Overlay({
      name: 'popup',
      events: {
        open: true,
        closeComplete: true,
      },
      click: {
        buttons: {
          close: '[data-js="close-overlay-popup"]',
        },
      },
      timeout: {
        delay: 2000,
      },
      options: {
        speed: 800,
      },
    });

    window.overlayPopup.init();

    // Fonction permettant de donner un attribut height au container de l'overlay
    const addHeightAttributeToContainer = (nameEventOverlay, targetContainerOverlay) => {
      const containerOverlay = document.querySelector(targetContainerOverlay);

      function onOpen() {
        containerOverlay.style.height = `${containerOverlay.clientHeight}px`;
      }

      function onCloseComplete() {
        containerOverlay.style.height = null;
      }

      function onResize() {
        containerOverlay.style.height = null;
        containerOverlay.style.height = `${containerOverlay.clientHeight}px`;
      }

      OBSERVER.add({
        name: `overlay${nameEventOverlay}`,
        events: `onOpenOverlay${nameEventOverlay}`,
        function: onOpen,
      });
      OBSERVER.add({
        name: `overlay${nameEventOverlay}`,
        events: `onCloseCompleteOverlay${nameEventOverlay}`,
        function: onCloseComplete,
      });
      OBSERVER.add({
        name: `overlay${nameEventOverlay}`,
        events: 'resize',
        function: onResize,
      });
      OBSERVER.on(`overlay${nameEventOverlay}`);
    };

    // Fonction permettant de donner un attribut height au container de l'overlay (mettre events open et closeComplete à true)
    addHeightAttributeToContainer('Popup', '[data-overlay-container="popup"]');
  }
}

export function overlayFastlinks() {
  window.overlayFastLinks = new Overlay({
    name: 'fastlinks',
    click: {
      buttons: {
        open: '[data-js="open-overlay-fastlinks"]',
        close: '[data-js="close-overlay-fastlinks"]',
      },
    },
    options: {
      speed: 800,
    },
  });

  window.overlayFastLinks.init();
}

export function overlayDirectory() {
  window.overlayDirectory = new Overlay({
    name: 'directory',
    click: {
      buttons: {
        open: '[data-js="open-overlay-directory"]',
        close: '[data-js="close-overlay-directory"]',
      },
    },
    options: {
      speed: 800,
    },
  });

  window.overlayDirectory.init();
}

export function overlayPage() {
  window.overlayPage = new Overlay({
    name: 'page',
    click: {
      buttons: {
        open: '[data-js="open-overlay-page"]',
        close: '[data-js="close-overlay-page"]',
      },
    },
    options: {
      speed: 800,
    },
  });

  window.overlayPage.init();
}

export function overlayCalendar() {
  window.overlayCalendar = new Overlay({
    name: 'calendar',
    click: {
      buttons: {
        open: '[data-js="open-overlay-calendar"]',
        close: '[data-js="close-overlay-calendar"]',
      },
    },
    options: {
      speed: 800,
    },
  });

  window.overlayCalendar.init();
}

export function overlaySearch() {
  window.overlaySearch = new Overlay({
    name: 'search',
    click: {
      buttons: {
        trigger: '[data-js="trigger-overlay-search"]',
        close: '[data-js="close-overlay-search"]',
      },
    },
    options: {
      speed: 800,
      closeOnResize: false,
    },
  });

  window.overlaySearch.init();

  const html = document.querySelector('html');

  let searchInputLength = 0;

  let ajaxRequest = false;
  const changeSection = (e) => {
    let searchInputLength = e;
    const html = document.querySelector('html');

    if (ajaxRequest) {
      // Si une requête est déjà en cours, l'annuler pour faire place à la prochaine
      ajaxRequest.abort();
      if (html.dataset.section !== 'suggestions') {
        window.overlaySearch.trigger('suggestions');
      }
    }

    if (searchInputLength.target.value.length >= 3) {
      ajaxRequest = $.request('rechercheAutocomplete::onFetchAutocomplete', {
        data: {q: e.currentTarget.value},
        update: {'rechercheAutocomplete::autocomplete': '#overlay-search #autocomplete'},
        complete(data) {
          // Results loaded.
          ajaxRequest = false;

          // On affiche l'overlay des suggestions juste si ya des résultats
          if (data.responseJSON && data.responseJSON['rechercheAutocomplete::autocomplete']) {
            if (html.dataset.section !== 'results') {
              window.overlaySearch.trigger('results');
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (html.dataset.section !== 'suggestions') {
              window.overlaySearch.trigger('suggestions');
            }
          }
        },
      });
    } else if (searchInputLength.target.value.length < 3 && html.dataset.section !== 'suggestions') {
      window.overlaySearch.trigger('suggestions');

    }

  };

  function openOnInputFocus() {
    html.dataset.section = '';

    if (!html.dataset.section) {
      if (searchInputLength >= 3) {
        window.overlaySearch.trigger('results');
      } else if (searchInputLength < 3) {
        window.overlaySearch.trigger('suggestions');
      }
    }
  }

  function onClearInput() {
    searchInputLength = 0;

    if (html.dataset.section === 'results') {
      window.overlaySearch.trigger('suggestions');
    }
  }

  OBSERVER.add({
    name: 'overlaySearch',
    events: 'focus',
    function: openOnInputFocus,
    targets: '[data-input="overlay-search-input"]',
  });

  OBSERVER.add({
    name: 'overlaySearch',
    events: 'input',
    function: changeSection,
    targets: '[data-input="overlay-search-input"]',
  });

  OBSERVER.add({
    name: 'overlaySearch',
    events: 'click',
    function: onClearInput,
    targets: '[data-js="clear-input"]',
  });

  OBSERVER.on('overlaySearch');
}

export function overlayMenu() {
  window.overlayMenu = new Overlay({
    name: 'menu',
    click: {
      buttons: {
        trigger: '[data-js="trigger-overlay-menu"]',
        close: '[data-js="close-overlay-menu"]',
      },
    },
    events: {
      close: true,
    },
    options: {
      speed: 800,
      closeOnResize: false,
    },
  });

  window.overlayMenu.init();

  const html = document.querySelector('html');
  const overlay = document.querySelector('.c-overlay-menu-next');
  const buttons = document.querySelectorAll('[data-js="trigger-overlay-menu-next"]');

  let windowWidth = window.innerWidth;

  window.addEventListener('resize', () => { windowWidth = window.innerWidth; });

  buttons.forEach((button) => {
    const data = button.dataset.subsection;

    button.addEventListener('click', () => {
      if (!overlay.classList.contains('--triggered')) {
        overlay.classList.add('--triggered');
      }

      html.dataset.subsection = data;

      if (windowWidth > 768) {
        window.overlayMenu.overlay.options.speed = 1800;
      }
    });
  });

  const onClose = () => {
    overlay.classList.remove('--triggered');

    if (windowWidth > 768) {
      setTimeout(() => {
        html.dataset.subsection = '';
        window.overlayMenu.overlay.options.speed = 800;
      }, 2400);
    } else {
      html.dataset.subsection = '';
    }
  };

  const onClick = () => {
    html.dataset.subsection = '';
    overlay.classList.remove('--triggered');
    window.overlayMenu.overlay.options.speed = 800;
  };

  OBSERVER.add({
    name: 'overlayMenu',
    events: 'onCloseOverlayMenu',
    function: onClose,
  });
  OBSERVER.add({
    name: 'overlayMenu',
    events: 'click',
    function: onClick,
    targets: '.c-overlay-menu__button-back, [data-js="overlay-menu-next-back-button"]',
  });
  OBSERVER.on('overlayMenu');
}
