//----------------------------\\
//---- TimeoutEvent class ----\\
//----------------------------\\

export class TimeoutEvent {

  constructor(instance) {
    if (instance !== undefined)
      this.instance = instance

    this.init()
  }


  init() {
    let i
    let delay = this.instance.overlay.event.opening.delay !== undefined ? this.instance.overlay.event.opening.delay : this.instance.overlay.event.delay

    if (this.instance.overlay.event.cookie == '') {
      setTimeout(() => {
        this.instance.open()
      }, delay)
    } else {
      if (this.getCookie(this.instance.overlay.event.cookie) == '') {
        setTimeout(() => {
          this.instance.open()
          this.setCookie(this.instance.overlay.event.cookie, 'true', 7)
        }, delay)
      }
    }

    this.closeButtons = document.querySelectorAll(this.instance.overlay.structure.buttons.close)
    let closeButtonsLength = this.closeButtons.length
    for (i = 0; i < closeButtonsLength; i++) {
      this.boundClose = e => this.instance.close(e)
      this.closeButtons[i].addEventListener('click', this.boundClose, false)
    }
  }


  destroy() {
    let i

    let closeButtonsLength = this.closeButtons.length
    for (i = 0; i < closeButtonsLength; i++)
      this.closeButtons[i].removeEventListener('click', this.boundClose, false)
  }


  getCookie(cname) {
    var name = cname + '='
    var decodedCookie = decodeURIComponent(document.cookie)
    var ca = decodedCookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) == ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }


  setCookie(name, value, exdays = '') {
    let date = new Date()
    date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000))
    let expires = 'expires=' + date.toUTCString()
    document.cookie = name + '=' + value + ';' + expires + ';path=/'
  }
}
