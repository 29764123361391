/* eslint-disable no-restricted-globals */
import $ from 'jquery';
import ShareButtons from 'share-buttons/dist/share-buttons';

export default function initRepertoire() {

  //
  // Arrivé sur la page avec des segments dans l'url
  //

  const urlSegments = window.location.pathname.split('/');
  if (urlSegments.length > 2 && urlSegments[2] != '') { // S'il y a un segment de catégorie dans l'url
    if (document.querySelector('#accordion-'+urlSegments[2])) { // S'il y a bien une rubrique de sous-catégorie qui correspond au segment d'url
      const rubric = window.accordions.accordions.root.querySelector('#accordion-'+urlSegments[2]);
      window.accordions.open(rubric);
    }

    if (urlSegments.length == 3 && document.querySelector('#company-'+urlSegments[2])) { // S'il y a uniquement un slug, c'est peut être une entreprise directe
      // Call en ajax l'update du contenu de l'overlay
      $.request('onLoadOverlay', {
        data: { slug: urlSegments[2] }
      });
      window.overlayDirectory.open(); // Ouvrir l'overlay
    }
  }
  if (urlSegments.length > 3 && urlSegments[3] != '') { // S'il y a un segment d'entreprise dans l'url
    // Call en ajax l'update du contenu de l'overlay
    $.request('onLoadOverlay', {
      data: { slug: urlSegments[3] }
    });
    window.overlayDirectory.open(); // Ouvrir l'overlay
  }



  //
  // Modification de l'url selon des actions
  //

  // Modification de l'url à l'ouverture d'une rubrique (sous-catégorie)
  $('.js-accordion .js-accordion-toggle').click(function () {
    const cat = $(this).parent();
    if (cat.hasClass('js-accordion-opened')) {
      history.pushState({}, '', `/${window.location.pathname.split('/')[1]}/${cat.data('slug')}`);
    } else {
      history.pushState({}, '', `/${window.location.pathname.split('/')[1]}`);
    }

    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    ShareButtons.update();
  });

  // Ajout du slug dans l'url sur le click d'une entreprise
  $('button.c-small-company-card').click(function () {
    const slug = $(this).data('slug');
    history.pushState({}, '', `/${window.location.pathname.split('/')[1]}/${slug}`);

    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    ShareButtons.update();
  });

  // Retrait du slug de l'entreprise de l'url à la fermeture de l'overlay
  $('[data-js=close-overlay-directory]').click(() => {
    history.pushState({}, '', window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/')));

    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    ShareButtons.update();
  });
}

window.initRepertoire = initRepertoire;

function resetURL() {
  history.pushState({}, '', `/${window.location.pathname.split('/')[1]}`);

  document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
  ShareButtons.update();
}

window.resetURL = resetURL;
