//-----------------------\\
//---- Overlay class ----\\
//-----------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Class that create overlay (popup, sidepanel, others)
//
// new Overlay({
//   name: STRING[undefined] -> The overlay's name
//   structure: {
//     id: STRING['overlay'+name] -> The overlay's id
//     selector: STRING['html'] -> The selector where the active Class will be added
//     activeClass: STRING['show'+id] -> The class used for the overlay's activation
//     openingClass: STRING['opening'] -> the class added to the selector during the opening
//     closingClass: STRING['closing'] -> the class added to the selector during the closing
//     buttons: {
//       toggle: STRING[undefined] -> Selector opening the overlay
//       open: STRING[undefined] -> Selector closing the overlay
//       close: STRING[undefined] -> Selector toggling the overlay
//     }
//   },
//   event: {
//     name: STRING['click'] -> the event's name: mouseover, timeout or click
//     sensitive: BOOLEAN['false'] -> Trigger the event even if the overlay is already active or not
//     speed: NUMBER[600] -> The speed of the opening and closing animation
//     delay: NUMBER[0] -> The delay of the opening and closing animation
//     cookie: STRING[''] -> The cookie prevent the event timeout to be activated
//     opening: {
//       speed: NUMBER[undefined] -> The speed of the opening animation
//       delay: NUMBER[undefined] -> The delay of the opening animation
//       callback: FUNCTION[undefined] -> callback after the opening
//     },
//     closing {
//       speed: NUMBER[undefined] -> The speed of the closing animation
//       delay: NUMBER[undefined] -> The delay of the closing animation
//       callback: FUNCTION[undefined] -> callback after the closing
//     }
//   },
//   options {
//     goToSelector: BOOLEAN[false] -> Go back to the top of the page at the overlay's opening
//     global = BOOLEAN[false] -> Add the overlay's instance to a global variable (window)
//     noScroll: BOOLEAN[true] -> Remove to the user the right of scrolling in the web page
//     closeOnResize: BOOLEAN[true] -> Close the overlay on the resizing of the browser window
//   }
// })
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import { ClickEvent } from './CustomEvents/ClickEvent.js'
import { MouseoverEvent } from './CustomEvents/MouseoverEvent.js'
import { TimeoutEvent } from './CustomEvents/TimeoutEvent.js'
import { ResizeEvent } from './CustomEvents/ResizeEvent.js'
import { Debug } from './Debug.js'
import { getElementOffset } from './getElementOffset.js'
//-----------------------------------------------------------------------------------------------------------------

export default class Overlay {

  constructor(object) {
    this.overlay = object
    this.windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

    if (this.overlay.structure === undefined)
      this.overlay.structure = {}

    if (this.overlay.structure.id === undefined)
      this.overlay.structure.id = 'overlay' + this.overlay.name.charAt(0).toUpperCase() + this.overlay.name.slice(1)

    if (this.overlay.structure.selector === undefined)
      this.overlay.structure.selector = 'html'

    if (this.overlay.structure.activeClass === undefined)
      this.overlay.structure.activeClass = 'show' + this.overlay.structure.id.charAt(0).toUpperCase() + this.overlay.structure.id.slice(1)

    if (this.overlay.structure.openingClass === undefined)
      this.overlay.structure.openingClass = 'opening'

    if (this.overlay.structure.closingClass === undefined)
      this.overlay.structure.closingClass = 'closing'

    if (this.overlay.structure.buttons === undefined)
      this.overlay.structure.buttons = {}

    if (this.overlay.event === undefined)
      this.overlay.event = {}

    if (this.overlay.event.opening === undefined)
      this.overlay.event.opening = {}

    if (this.overlay.event.closing === undefined)
      this.overlay.event.closing = {}

    if (this.overlay.event.name === undefined)
      this.overlay.event.name = 'click'

    if (this.overlay.event.sensitive === undefined)
      this.overlay.event.sensitive = 'false'

    if (this.overlay.event.speed === undefined)
      this.overlay.event.speed = 300

    if (this.overlay.event.delay === undefined)
      this.overlay.event.delay = 0

    if (this.overlay.event.cookie === undefined)
      this.overlay.event.cookie = ''

    if (this.overlay.options === undefined)
      this.overlay.options = {}

    if (this.overlay.options.goToSelector === undefined)
      this.overlay.options.goToSelector = ''

    if (this.overlay.options.global === undefined)
      this.overlay.options.global = 'false'

    if (this.overlay.options.noScroll === undefined)
      this.overlay.options.noScroll = 'true'

    if (this.overlay.options.closeOnResize === undefined)
      this.overlay.options.closeOnResize = 'true'

    this.debug()
  }


  // Check if there is any error before initializing the class
  debug() {
    //this.overlay.debug = new Debug(this)
    //if(this.overlay.debug.hasError())
    //  console.log(this.overlay.debug.getError())
    //else
	
    this.init()
  }


  // Check if the overlay needs to be added to a global variable and add the event on
  // the overlay.
  init() {

    if (this.overlay.options.global)
      window['overlay' + this.overlay.name] = this

    if (this.overlay.event.name == 'click')
      this.event = new ClickEvent(this)
    else if (this.overlay.event.name == 'mouseover')
      this.event = new MouseoverEvent(this)
    else if (this.overlay.event.name == 'timeout' && document.querySelectorAll('#' + this.overlay.structure.id).length != 0)
      this.event = new TimeoutEvent(this)

    if (this.overlay.options.closeOnResize)
      this.resizeEvent = new ResizeEvent(this)
  }


  // Open the overlay, it will check if there is any callback, then it will add the active
  // class if it doesn't already exist on the selector. It will check if the back to top and
  // the no scroll option are true. The function will add the opening class and the timeout
  // will remove it. The timeout is for the animation duration from the CSS.
  open(e) {
    // Cancel the closing
    if (document.querySelector(this.overlay.structure.selector).classList.contains(this.overlay.structure.closingClass)) {
      clearTimeout(this.timeout)
      document.querySelector(this.overlay.structure.selector).classList.remove(this.overlay.structure.activeClass)
      document.querySelector(this.overlay.structure.selector).classList.remove(this.overlay.structure.closingClass)
    }

    if (typeof this.overlay.event.opening.callback === 'function') {
      if (this.overlay.event.sensitive) {
        this.overlay.event.opening.callback(e)
      } else {
        if (!document.querySelector(this.overlay.structure.selector).classList.contains(this.overlay.structure.activeClass))
          this.overlay.event.opening.callback(e)
      }
    }

    if (!document.querySelector(this.overlay.structure.selector).classList.contains(this.overlay.structure.activeClass)) {
      if (this.overlay.options.goToSelector !== '')
        document.querySelector('html').scrollTop = getElementOffset(document.querySelector(this.overlay.options.goToSelector)).top
      if (this.overlay.options.noScroll)
        this.addNoScroll()
      document.querySelector(this.overlay.structure.selector).classList.add(this.overlay.structure.openingClass)
      document.querySelector(this.overlay.structure.selector).classList.add(this.overlay.structure.activeClass)
      this.timeout = setTimeout(() => {
        document.querySelector(this.overlay.structure.selector).classList.remove(this.overlay.structure.openingClass)
      },(this.overlay.event.opening.speed !== undefined ? this.overlay.event.opening.speed : this.overlay.event.speed))
    }
  }


  // Close the overlay, it will check if there is any callback, then if the active class
  // is there, the function will check for the no scroll option. It will add the closing
  // class and the timeout will remove this class and the active class.
  close(e) {
    // Cancel the opening
    if (document.querySelector(this.overlay.structure.selector).classList.contains(this.overlay.structure.openingClass)) {
      clearTimeout(this.timeout)
      document.querySelector(this.overlay.structure.selector).classList.remove(this.overlay.structure.openingClass)
    }

    if (typeof this.overlay.event.closing.callback === 'function') {
      if (this.overlay.event.sensitive) {
        this.overlay.event.closing.callback(e)
      } else {
        if (!document.querySelector(this.overlay.structure.selector).classList.contains(this.overlay.structure.activeClass))
          this.overlay.event.closing.callback(e)
      }
    }

    if (document.querySelector(this.overlay.structure.selector).classList.contains(this.overlay.structure.activeClass)) {
      if (this.overlay.options.noScroll)
        this.removeNoScroll()
      document.querySelector(this.overlay.structure.selector).classList.add(this.overlay.structure.closingClass)
      this.timeout = setTimeout(() => {
        document.querySelector(this.overlay.structure.selector).classList.remove(this.overlay.structure.activeClass)
        document.querySelector(this.overlay.structure.selector).classList.remove(this.overlay.structure.closingClass)
      },(this.overlay.event.closing.speed !== undefined ? this.overlay.event.closing.speed : this.overlay.event.speed))
    }
  }


  // Toggle the opening and the closing of the overlay
  toggle(e) {
    if (!document.querySelector(this.overlay.structure.selector).classList.contains(this.overlay.structure.activeClass) || document.querySelector(this.overlay.structure.selector).classList.contains(this.overlay.structure.closingClass)) {
      this.open(e)
    } else {
      this.close(e)
    }
  }


  // This function will close the overlay if the user resize the browser window.
  resize() {
    let newWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

    if (newWidth != this.windowWidth) {
      this.windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      this.close()
    }
  }


  // Add the no scroll removing to the user the right to scroll again. There is css
  // associated to the noscroll class.
  addNoScroll() {
    let documentHeight = document.documentElement.scrollHeight
    let windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

    if (documentHeight > windowHeight) {
      let scrollTop = -document.querySelector('html').scrollTop + 'px'
      document.querySelector('html').classList.add('noscroll')
      document.querySelector('html').style.top = scrollTop
    }
  }


  // Remove the no scroll giving to the user the right to scroll again.
  removeNoScroll() {
    if (this.overlay.options.goToSelector !== '') {
      document.querySelector('html').classList.remove('noscroll')
      document.querySelector('html').scrollTop = getElementOffset(document.querySelector(this.overlay.options.goToSelector)).top
    } else {
      let scrollPosition = parseInt(document.querySelector('html').style.top)
      document.querySelector('html').classList.remove('noscroll')
      document.querySelector('html').scrollTop = -scrollPosition
    }
  }


  // Destroy the events of the overlay.
  destroy() {
    this.event.destroy()

    if (this.overlay.options.closeOnResize)
      this.resizeEvent.destroy()
  }
}


