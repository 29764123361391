import $ from 'jquery';
import { Datepicker } from 'vanillajs-datepicker';
import { OBSERVER } from '../plugins';
import { isVisible } from './helper';

// Permet de créer un calendrier
export function calendar() {
  if (!document.querySelector('.js-calendar')) {
    // s'il n'y a pas d'events
    return;
  }

  const eventsDatesList = document.querySelector('.js-calendar').dataset.list;
  const eventsDatesEnabled = eventsDatesList.split(',');
  const datepickerCalendar = document.querySelector('.js-calendar');

  Datepicker.locales.fr = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: "Aujourd'hui",
    monthsTitle: 'Mois',
    clear: 'Effacer',
    weekStart: 0,
    format: 'dd/mm/yyyy',
  };

  // eslint-disable-next-line no-unused-vars
  const datepicker = new Datepicker(datepickerCalendar, {
    language: 'fr',
    prevArrow: `<svg><use xlink:href="/themes/${window.config.theme_path}/assets/medias/images/icons/symbols.svg#ico-chevron"></use></svg>`,
    nextArrow: `<svg><use xlink:href="/themes/${window.config.theme_path}/assets/medias/images/icons/symbols.svg#ico-chevron"></use></svg>`,
    maxView: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: (date) => {
      // Rendre seulement les dates de la liste d'événements disponibles
      const allDates = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      return eventsDatesEnabled.indexOf(allDates) !== -1;
    },
  });

  const onChangeDate = (e) => {
    const theTimestamp = Date.parse(e.detail.date) / 1000; // Le timestamp du datepicker est en millisecondes, il faut le mettre en secondes
    $.request('onChangeDate', {
      data: { dateFilter: theTimestamp },
      // update: {'evenementlist::eventslist':'#eventsWrapper'},
      // complete: function (data) { data.then(function(data){ eventsLoaded(data) })},
      complete: (data) => {
        data.then((data2) => {
          // eslint-disable-next-line no-undef
          Barba.Pjax.goTo(`/evenements/1/${data2.date}`);
        });
      },
    });

    // Fermer l'overlay quand on clique
    // document.querySelector('#overlayCalendar .wrapper a.close span.x').click();
  };

  OBSERVER.add({
    name: 'calendar',
    events: 'changeDate',
    targets: '.js-calendar',
    function: onChangeDate,
  });
  OBSERVER.on('calendar');
}

// Copier du texte
export function copyTextToClipboard(text) {
  const pos = $(document).scrollTop();

  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error(err);
  }

  document.body.removeChild(textArea);
  $(document).scrollTop(pos);
}

// Ajoute les metas pour le contenu og
export function ogContent(data) {
  const ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1];
  const ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1];
  const ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1];
  const ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1];
  document.querySelector('meta[property="og:url"]').setAttribute('content', ogUrl);
  document.querySelector('meta[property="og:image"]').setAttribute('content', ogImage);
  document.querySelector('meta[property="og:title"]').setAttribute('content', ogTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', ogDescription);
}

// Permet d'aller au backend
export function backendKeyBind() {
  $('body').on('keydown', (e) => {
    const keycode = e.keyCode ? e.keyCode : e.which;
    if ((e.ctrlKey && keycode === 13) || (e.metaKey && keycode === 13)) {
      window.location = '/administration';
      return false;
    }
    return true;
  });
}

export function uncheckCheckboxes(object) {
  const { parent } = object;

  const checkWhichBoxIsChecked = (e) => {
    const targetedCheckbox = e.target;
    const checkboxAttribute = targetedCheckbox.getAttribute('data-js');

    if (checkboxAttribute === 'primary-checkbox') {
      const primaryCheckbox = targetedCheckbox;
      const secondaryCheckboxes = document.querySelectorAll(`${parent} [data-js="secondary-checkbox"]`);
      let i;

      if (primaryCheckbox.checked) {
        for (i = 0; i < secondaryCheckboxes.length; i += 1) {
          secondaryCheckboxes[i].checked = false;
        }
      }
    } else if (checkboxAttribute === 'secondary-checkbox') {
      const primaryCheckbox = document.querySelector(`${parent} [data-js="primary-checkbox"]`);
      const secondaryCheckbox = targetedCheckbox;

      if (secondaryCheckbox.checked) {
        primaryCheckbox.checked = false;
      }
    }
  };

  OBSERVER.add({
    name: 'uncheckCheckboxes',
    events: 'click',
    targets: `${parent} [data-js="primary-checkbox"], ${parent} [data-js="secondary-checkbox"]`,
    function: checkWhichBoxIsChecked,
  });

  OBSERVER.on('uncheckCheckboxes');
}

export function isFooterVisible(elements) {
  const footer = document.querySelector('.footer');
  const allElements = document.querySelectorAll(elements);

  const AddOrRemoveClass = () => {
    if (isVisible(footer)) {
      for (let i = 0; i < allElements.length; i += 1) {
        const element = allElements[i];

        element.classList.add('--footer-is-visible');
      }
    } else {
      for (let i = 0; i < allElements.length; i += 1) {
        const element = allElements[i];

        if (element.classList.contains('--footer-is-visible')) {
          element.classList.remove('--footer-is-visible');
        }
      }
    }
  };

  AddOrRemoveClass();

  OBSERVER.add({
    name: 'footerIsVisible',
    events: 'scroll',
    function: AddOrRemoveClass,
  });
  OBSERVER.on('footerIsVisible');
}

export function setHeaderLeftWidth() {
  const headerLeft = document.querySelector('.header__left');

  if (!headerLeft) {
    return;
  }

  const onResize = () => {
    const headerLeftWidth = headerLeft.offsetWidth;
    document.documentElement.style.setProperty('--header-left-width', `${headerLeftWidth}px`);
  };

  onResize();

  OBSERVER.add({
    name: 'createVariable',
    events: 'resize',
    targets: 'window',
    function: onResize,
  });

  OBSERVER.on('createVariable');
}

export function setScrollDestination() {
  const potentialDestinations = document.querySelectorAll('.potential-scroll-destination');
  if (potentialDestinations.length)
    potentialDestinations[0].dataset['scrollerDestination'] = 'scroll-to-content';
}
