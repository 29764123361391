import { OBSERVER } from '../plugins';

export default async function changeSelect(select) {
  const DROPDOWN = document.querySelector(select);
  function triggerChange() {
    const e = new Event('change', { bubbles: true });
    DROPDOWN.dispatchEvent(e);
  }

  if (DROPDOWN && DROPDOWN.nextElementSibling) {
    const OPTIONS = DROPDOWN.nextElementSibling.querySelectorAll('.dropdown-option');

    OBSERVER.add({
      name: 'triggerChange',
      events: 'click',
      targets: OPTIONS,
      function: triggerChange,
    });
    OBSERVER.on('triggerChange');
  }
}
