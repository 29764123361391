// Documentation: https://atomiks.github.io/tippyjs
import tippy from 'tippy.js';

export function tippyHomeBanner() {
  window.tippyHomeBanner = tippy('[data-js="photo-credit-home-banner"]', {
    theme: 'home-banner',
    placement: 'bottom-end',
  });
}

export function tippyForm() {
  window.tippyForm = tippy('[data-js="photo-credit-form"]', {
    theme: 'form',
  });
}

export function tippyPageTitle() {
  window.tippyPageTitle = tippy('[data-js="photo-credit-page-title"]', {
    theme: 'page-title',
  });
}

export function tippyShowcase() {
  window.tippyShowcase = tippy('[data-js="photo-credit-showcase"]', {
    theme: 'showcase',
  });
}
