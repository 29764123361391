import $ from 'jquery';

// Fonction contenant tous les messages
function getMessages() {
  let messages;

  if ($('html')[0].lang === 'en') {
    messages = {
      required: 'This field is required.',
      select: 'Please chose an option.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.',
      postale: 'Please enter a valid ZIP code.',
      date: 'Please enter a valid date.',
      accept: 'The file must be a document pdf.',
      file: 'Please provide a adequate file.',
    };
  } else {
    messages = {
      required: 'Ce champ est obligatoire.',
      select: 'Veuillez sélectionner une option.',
      email: 'Veuillez fournir une adresse électronique valide.',
      phone: 'Veuillez fournir un numéro de téléphone valide.',
      postale: 'Veuillez fournir un code postal valide.',
      date: 'Veuillez fournir une date valide.',
      accept: 'Le fichier doit être un document pdf.',
      file: 'Veuillez lier le fichier adéquat pour ce champ.',
    };
  }

  return messages;
}

// Fonction exécutant la validation de chaque formulaire
export function formValidation(formElement, submit, rules, messages, handler) {
  $.validator.setDefaults({
    ignore: [],
  });
  $(formElement).validate({
    debug: false,
    errorElement: 'div',
    focusInvalid: false,
    invalidHandler: (_, validator) => {
      if (!validator.numberOfInvalids()) {
        return; // eslint-disable-next-line semi
      }

      const nbProjectError = validator.errorList.find((item) => item.element.name === 'nbProject');

      if (nbProjectError === undefined) {
        $('html, body').animate({ scrollTop: $(validator.errorList[0].element).offset().top - 50 }, 500);
      }
    },
    rules,
    messages,
    errorPlacement: (error, element) => {
      error.appendTo(element.closest('.js-field').find('.js-form-error'));
    },
    success: (_, element) => {
      const errorField = $(element).closest('.js-field');
      errorField.removeClass('--error');
    },
    highlight: (element) => {
      // Ajouter la class d'erreur au champs
      const errorField = $(element).closest('.js-field');
      errorField.addClass('--error');
    },
    submitHandler: () => {
      if (typeof handler !== 'undefined') {
        handler();
        return false;
      }
      return true;
    },
  });
  // Mettre à jour la validation d'un select au change
  $('select:not(.ignoreValidation)').on('change', function selectChange() {
    $(this).valid();
  });
  $(submit).on('click', () => {
    $(formElement).submit();
  });
}

// La validation des courriels
function validationEmail() {
  // eslint-disable-next-line func-names
  $.validator.addMethod('courriel', function (value, element) {
    // eslint-disable-next-line max-len
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value);
  });
}

// La validation du formulaire d'exemple
export function formExample() {
  validationEmail();

  const m = getMessages();

  const rules = {
    dropdownExample: { required: true },
    firstname: { required: true },
    lastname: { required: true },
    email: { required: true, courriel: true },
    phone: { required: true, minlength: 12 },
    'files[]': { accept: false },
    message: { required: true },
    cv: { required: true },
    'radioExampleInline[]': { required: true },
    'checkboxExample[]': { required: true },
    time: { required: true },
  };
  const messages = {
    dropdownExample: { required: m.required },
    firstname: { required: m.required },
    lastname: { required: m.required },
    email: { required: m.required, courriel: m.email, email: m.email },
    phone: { required: m.required, minlength: m.phone },
    message: { required: m.required },
    cv: { required: m.required },
    'radioExampleInline[]': { required: m.required },
    'checkboxExample[]': { required: m.required },
    time: { required: m.required },
  };

  formValidation('#form-example', '#form-example-submit', rules, messages);
}

// La validation du formulaire dynamique
export function formDynamic() {
  validationEmail();

  const m = getMessages();

  const rules = {
    treeType: { required: m.required },
    dropdownDynamicTreeLocalisation: { required: true },
    reasonForTreeFelling: { required: true },
    name: { required: true },
    address: { required: true },
    city: { required: true },
    zip: { required: true },
    phone: { required: true, minlength: 12 },
    email: { required: true, courriel: true },
    workAddress: { required: true },
    workCity: { required: true },
    workZip: { required: true },
    signature: { required: true },
    date: { required: true },
  };
  const messages = {
    treeType: { required: m.required },
    dropdownDynamicTreeLocalisation: { required: m.required },
    reasonForTreeFelling: { required: m.required },
    name: { required: m.required },
    address: { required: m.required },
    city: { required: m.required },
    zip: { required: m.required },
    phone: { required: m.required, minlength: m.phone },
    email: { required: m.required, courriel: m.email, email: m.email },
    workAddress: { required: m.required },
    workCity: { required: m.required },
    workZip: { required: m.required },
    signature: { required: m.required },
    date: { required: m.required },
  };

  formValidation('#form-dynamic', '#form-dynamic-submit', rules, messages);
}

// La validation du formulaire d'emplois
export function formJobs() {
  validationEmail();

  $.validator.addMethod('filesize', function (value, element, param) {
    return this.optional(element) || (element.files[0].size <= param);
  }, 'La taille maximale du fichier doit être 5MB');

  const m = getMessages();

  const rules = {
    firstname: { required: true },
    lastname: { required: true },
    email: { required: true, courriel: true },
    phone: { required: true, minlength: 12 },
    cv: { accept: 'pdf', filesize: 5242880, required: true },
    motivation: { accept: 'pdf', filesize: 5242880 },
  };
  const messages = {
    firstname: { required: m.required },
    lastname: { required: m.required },
    email: { required: m.required, courriel: m.email, email: m.email },
    phone: { required: m.required, minlength: m.phone },
    cv: { accept: m.accept, required: m.required },
    motivation: { accept: m.accept },
  };

  formValidation('#form-jobs', '#form-jobs-submit', rules, messages);
}

// La validation du formulaire d'emplois
export function formContact() {
  validationEmail();

  const m = getMessages();

  const rules = {
    dropdownContact: { required: true },
    firstname: { required: true },
    lastname: { required: true },
    email: { required: true, courriel: true },
    phone: { required: true, minlength: 12 },
    message: { required: true },
  };
  const messages = {
    dropdownContact: { required: m.required },
    firstname: { required: m.required },
    lastname: { required: m.required },
    email: { required: m.required, courriel: m.email, email: m.email },
    phone: { required: m.required, minlength: m.phone },
    message: { required: m.required },
  };

  formValidation('#form-contact', '#form-contact-submit', rules, messages);
}

// La validation du formulaire d'infolettre
export function formNewsletter() {
  validationEmail();

  const m = getMessages();

  const rules = {
    email: { required: true, courriel: true },
    emailConfirmation: { required: true, equalTo: '#form-newsletter-email', courriel: true }
  };
  const messages = {
    email: { required: m.required, courriel: m.email, email: m.email },
    emailConfirmation: { required: m.required, equalTo: 'Les adresses courriel ne correspondent pas.', courriel: m.email, email: m.email },
  };

  formValidation('#form-newsletter', '#form-newsletter-submit', rules, messages);
}
