import barba from '@barba/core';
import LazyLoad from 'vanilla-lazyload';
import Accordions from './classes/accordions';
import ShareButtons from 'share-buttons/dist/share-buttons';
import Scroller from '@blankonumerique/blanko-scripts/scroller';
import CharacterCount from '@blankonumerique/blanko-scripts/character-count';
import { SCROLLFIRE, OBSERVER } from './plugins';
import defaultTransition from './transitions/default-transition';
import {
  backendKeyBind, calendar, isFooterVisible, ogContent, setHeaderLeftWidth, uncheckCheckboxes, setScrollDestination,
} from './functions/functions';
import rubrics from './functions/rubrics';
import dynamicPackage from './functions/dynamic';
import {
  formsPackage, inputsAndTextareaLabel, clearInput, tailSelect,
} from './functions/form';
import Overlay from './vendor/overlay';
import {
  formContact, formDynamic, formExample, formJobs, formNewsletter
} from './functions/validation';
import masksPackage from './functions/masks';
import { hundredVH, removeHoverOnMobile } from './functions/helper';
import {
  tippyForm, tippyHomeBanner, tippyPageTitle, tippyShowcase,
} from './functions/tooltip';
import customizedButtons from './functions/customizedButtons';
import { swiperAlert, swiperFilters, swiperHomeBanner } from './functions/swipers';
import {
  overlayShare, overlayPopup, overlayFastlinks, overlayDirectory, overlayPage, overlayCalendar, overlaySearch, overlayMenu,
} from './functions/overlays';
import initRepertoire from './functions/repertoire';
import changeSelect from './functions/changeSelect';
import {
  googleMap, destroyMap, overlayLocation, overlayLocations, goBackMap, subMenu, drawers,
} from './functions/map/map';

window.SCROLLFIRE = SCROLLFIRE

const initBarba = () => {
  barba.hooks.beforeEnter(async ({ current }) => {
    if (current.container != null) {
      current.container.remove();
    }
    setScrollDestination(); // Seulement utilisé sur l'accueil, mais doit être appeler avant le Scroller
    await Scroller.init();
    customizedButtons();
    backendKeyBind();
    hundredVH();
    ShareButtons.update();
    SCROLLFIRE.init();
    removeHoverOnMobile();
    inputsAndTextareaLabel();
    clearInput();
    swiperAlert();
    overlayPopup();
    overlaySearch();
    overlayMenu();
    setHeaderLeftWidth();
  });

  barba.hooks.enter((data) => {
    ogContent(data);
  });

  barba.hooks.afterLeave(() => {
    OBSERVER.allOff();
    OBSERVER.removeAll();
    Overlay.destroyAll();
    SCROLLFIRE.destroy();
  });

  barba.hooks.after = (() => {
    fbq('track', '3216460341999653', 'PageView')
  })

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [defaultTransition()],

    views: [
      {
        namespace: 'standardView',
        async beforeEnter() {
          rubrics();
          formsPackage();
          masksPackage();
          dynamicPackage();
          formJobs();
          formNewsletter();
          swiperFilters();
          uncheckCheckboxes({ parent: '.c-page-title__categories-filter' });
          isFooterVisible(['.o-aside', '.c-alert']);
          tippyForm();
          tippyPageTitle();
          tippyShowcase();
          overlayShare();
          overlayFastlinks();
          overlayPage();
          changeSelect('#docAlphaSelect');
          changeSelect('#docDateSelect');
          CharacterCount.init();
          window.lazyload = new LazyLoad();
          // eslint-disable-next-line no-undef
          initFormBuilder();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'homeView',
        beforeEnter() {
          swiperHomeBanner();
          CharacterCount.init();
          tippyHomeBanner();
          tippyShowcase();
          isFooterVisible(['.c-alert']);
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'publicationsView',
        beforeEnter() {
          swiperFilters();
          uncheckCheckboxes({ parent: '.c-page-title__categories-filter' });
          isFooterVisible(['.o-aside', '.c-alert']);
          overlayShare();
          overlayFastlinks();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'publicationView',
        beforeEnter() {
          CharacterCount.init();
          isFooterVisible(['.o-aside', '.c-alert']);
          tippyPageTitle();
          overlayShare();
          overlayFastlinks();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'eventsView',
        beforeEnter() {
          swiperFilters();
          uncheckCheckboxes({ parent: '.c-page-title__categories-filter' });
          calendar();
          isFooterVisible(['.o-aside', '.c-alert']);
          overlayShare();
          overlayFastlinks();
          overlayCalendar();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'eventView',
        beforeEnter() {
          CharacterCount.init();
          isFooterVisible(['.o-aside', '.c-alert']);
          tippyPageTitle();
          overlayShare();
          overlayFastlinks();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'directoryView',
        beforeEnter() {
          const accordions = new Accordions();
          accordions.offset = 0;
          accordions.init();
          window.accordions = accordions;

          isFooterVisible(['.o-aside', '.c-alert']);
          tailSelect();
          tippyShowcase();
          overlayShare();
          overlayFastlinks();
          overlayDirectory();
          initRepertoire();
          changeSelect('#directory-filter');
          changeSelect('#directory-sort');
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'searchView',
        beforeEnter() {
          swiperFilters();
          uncheckCheckboxes({ parent: '.c-page-title__categories-filter' });
          CharacterCount.init();
          isFooterVisible(['.o-aside', '.c-alert']);
          overlayShare();
          overlayFastlinks();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'contactView',
        beforeEnter() {
          rubrics();
          isFooterVisible(['.o-aside', '.c-alert']);
          tippyPageTitle();
          tippyShowcase();
          tailSelect();
          formsPackage();
          masksPackage();
          formContact();
          overlayShare();
          overlayFastlinks();
          overlayPage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'mapView',
        beforeEnter() {
          overlayLocation();
          overlayLocations();
          goBackMap();
          subMenu();
          drawers();
          googleMap();
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          destroyMap();
          window.lazyload.destroy();
        },
      },
    ],
  });
};

document.addEventListener('DOMContentLoaded', () => {
  initBarba();
});
